import React from "react";
import PropTypes from "prop-types";
import Content, { HTMLContent } from "../components/Content";
import showdown from "showdown";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import imgBehance from "../img/icons/rrss/behance.svg";
import imgDomestica from "../img/icons/rrss/domestica.svg";
import imgDribble from "../img/icons/rrss/dribble.svg";
import imgFacebook from "../img/icons/rrss/facebook.svg";
import imgInstagram from "../img/icons/rrss/instagram.svg";
import logoTantasCosas from "../img/icons/tantas-cosas-son-todavia-posibles.svg";

export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  title1,
  p1,
  p2,
  title2,
  contentpress,
  title3,
  contentawards,
  title4,
  contentclients,
  title5,
  contentccontact,
  title6,
  title7,
  contentagents
}) => {
  const PageContent = contentComponent || Content;
  const converter = new showdown.Converter();
  const p1html = converter.makeHtml(p1);
  const p2html = converter.makeHtml(p2);
  const contentpresshtml = converter.makeHtml(contentpress);
  const contentawardshtml = converter.makeHtml(contentawards);
  const contentclientshtml = converter.makeHtml(contentclients);
  const contentccontacthtml = converter.makeHtml(contentccontact);
  const contentagentshtml = converter.makeHtml(contentagents);

  return (
    <div className="AboutPage">
      <div className="containerAbout">
        <img className="imageHeader" src="img/mauco-sosa-about.jpg" alt="" />
        <div className="columns">
          <div className="column main">
            <h1 className="title">{title1}</h1>
            <div className="columns">
              {p1 && (
                <div className="column p1">
                  <PageContent content={p1html} />
                </div>
              )}
              {p2 && (
                <div className="column p2">
                  <PageContent content={p2html} />
                </div>
              )}
            </div>
            <div className="columns">
              <div className="column p1 press">
                <h2 className="title">{title2}</h2>
                {contentpress && (
                  <div className="contentPress">
                    <PageContent content={contentpresshtml} />
                  </div>
                )}
              </div>
              <div className="column p2 awards">
                <h2 className="title">{title3}</h2>
                {contentawards && (
                  <div className="contentAwards">
                    <PageContent content={contentawardshtml} />
                  </div>
                )}
              </div>
            </div>
            <PageContent className="content" content={content} />
            <div className="selectedClients">
              <h2 className="title">{title4}</h2>
              <div
                className="selectedClientsContentWrapper"
                onClick={e => {
                  if (
                    e.target.alt &&
                    e.target.alt.length > 4 &&
                    "http" === e.target.alt.substr(0, 4)
                  ) {
                    let popup = window.open(e.target.alt, "_blank no-referer");
                    popup.opener = null;
                  }
                }}
              >
                <PageContent
                  content={contentclientshtml}
                  className="selectedClientsContent"
                />
              </div>
            </div>
          </div>
          <div className="column sidebar">
            <div className="rows">
              <div className="row contact">
                <h2 className="title">{title5}</h2>
                {contentccontact && (
                  <div className="contactContent">
                    <PageContent content={contentccontacthtml} />
                  </div>
                )}
              </div>
              <div className="row rrss">
                <h2 className="title">{title6}</h2>
                <div className="rrssContent">
                  <a
                    className="rrssRed"
                    href="https://www.behance.net/mauco"
                    target="_blank no-referer"
                  >
                    <i className="fas fa-behance">
                      <img src={imgBehance} alt="logo BEHANCE" />
                    </i>
                  </a>
                  <a
                    className="rrssRed"
                    href="https://www.instagram.com/maucosis/"
                    target="_blank no-referer"
                  >
                    <i className="fas fa-instagram">
                      <img src={imgInstagram} alt="logo INSTAGRAM" />
                    </i>
                  </a>
                  <a
                    className="rrssRed"
                    href="https://www.facebook.com/maucosis/"
                    target="_blank no-referer"
                  >
                    <i className="fas fa-facebook">
                      <img src={imgFacebook} alt="logo FACEBOOK" />
                    </i>
                  </a>
                  <a
                    className="rrssRed"
                    href="https://www.domestika.org/es/mauco/portfolio"
                    target="_blank no-referer"
                  >
                    <i className="fas fa-domestica">
                      <img src={imgDomestica} alt="logo DOMESTICA" />
                    </i>
                  </a>
                  <a
                    className="rrssRed"
                    href="https://dribbble.com/maucosis"
                    target="_blank no-referer"
                  >
                    <i className="fas fa-dribbble">
                      <img src={imgDribble} alt="logo DRIBBBLE" />
                    </i>
                  </a>
                </div>
              </div>
              <div className="row agents">
                <h2 className="title">{title7}</h2>
                {contentagents && (
                  <div className="agentsContent contentAgents">
                    <PageContent content={contentagentshtml} />
                  </div>
                )}
              </div>
              <div className="row rowTantasCosas">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a href="#">
                  <img
                    src={logoTantasCosas}
                    alt="MAUCO Tantas cosas son todavía posibles."
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        {...post.frontmatter}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        templateKey
        description
        img
        title1
        p1
        p2
        title2
        contentpress
        title3
        contentawards
        title4
        contentclients
        title5
        contentccontact
        title6
        title7
        contentagents
      }
    }
  }
`;
/*
query WorkQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "about-page" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug

          }
          frontmatter {
            title
            templateKey
            description
            img
            title
            p1
            p2
            title2
            contentpress
            title3
            contentawards
            title4
            contentclients
            title5
            contentccontact
            title6
            title7
            contentagents
          }
        }
      }
    }
  }
*/
